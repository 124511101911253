// import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import Modal from 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/offcanvas';
// import 'bootstrap/js/dist/popover';
import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

document.addEventListener('DOMContentLoaded', () => {
  // Helper to update the href of #speedbump-link
  const updateSpeedbumpLink = (newHref) => {
    const speedbumpLink = document.querySelector('#speedbump-link');
    if (speedbumpLink) {
      speedbumpLink.setAttribute('href', newHref);
    }
  };

  // Helper to show modal
  const showModal = (element) => {
    if (element) {
      const modal = new Modal(element);
      modal.show();
    } else {
      console.warn('Modal element not found in DOM');
    }
  };

  const links = document.querySelectorAll(
    'a:not(#speedbump-link), button:not(#speedbump-link)'
  );
  const speedbumpElement = document.getElementById('speedbump');

  // Reset speedbump link when modal is closed
  if (speedbumpElement) {
    speedbumpElement.addEventListener('hidden.bs.modal', () =>
      updateSpeedbumpLink('')
    );
  } else {
    console.warn('Modal element not found in DOM');
  }

  links.forEach((el) => {
    el.addEventListener('click', function (e) {
      const href = this.getAttribute('href') || this.getAttribute('data-href');

      if (!href || /^(mailto:|tel:|#)/.test(href)) {
        return;
      }

      const tempAnchor = document.createElement('a');
      tempAnchor.href = href;
      const currentDomain = window.location.hostname;

      if (tempAnchor.hostname !== '' && tempAnchor.hostname !== currentDomain) {
        e.preventDefault();
        updateSpeedbumpLink(href);
        showModal(speedbumpElement);
      }
    });
  });

  // Add class to paragraphs that start with a special character
  function hangIndent() {
    const specialChars = ['*', '†'];

    const paragraphs = document.querySelectorAll('.fine-print p');

    paragraphs.forEach(p => {
      const firstChar = p.textContent.trim().charAt(0);

      if (specialChars.includes(firstChar)) {
        p.classList.add('hanging-punctuation');
      }
    });
  }

  hangIndent();

  // Footer menu cookie preference center toggle
  const cookieToggleLink = document.querySelector("footer [href='#cookie-info']");
  if (cookieToggleLink) {
    cookieToggleLink.addEventListener('click', function (event) {
      event.preventDefault();
      try {
        OneTrust.ToggleInfoDisplay();
      }
    });
  }
});
